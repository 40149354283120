<template>
  <v-app>
    <v-layout row wrap align-center justify-center  style="background: #E3F2FD; background: linear-gradient(to bottom, #1976D2, #1E88E5, #E3F2FD);">
    <v-container>
      <v-flex lg12 v-if="resolutionScreen >= 500">    
          <v-layout row wrap align-center justify-center >
             <v-row  justify="center">
              <v-img :src="require('@/assets/img/logo_one-platform-for-drak-background.e306d38.png')" max-width="700" max-height="300" />
             </v-row>
          </v-layout>
          <v-layout row wrap align-center justify-center>
            <v-flex xs12 lg12 class="text-center">
              <span style="color:white; font-size: 25px;">ต้องมีบัญชี One Platform เพื่อใช้ในการลงทะเบียน</span>
              <br />
              <span style="color: white; font-size: 25px;">คุณมีบัญชี One Platform หรือไม่?</span>
            </v-flex>
          </v-layout>
          <v-layout row wrap align-center justify-center>
            <v-flex xs6 lg6>
              <div class="my-2 text-right">
                <v-btn color="success" x-large dark class="button button5" id="btn" @click="fn_login_regist()">
                  <div>
                  <v-flex xs12 lg12>
                    <v-icon style="font-size: 36px;">done</v-icon>
                  </v-flex>
                  <v-flex xs12 lg12>
                    <div style="font-size: 20px;">มีบัญชี</div>
                  </v-flex>
                  </div>
                </v-btn>
              </div>
            </v-flex>
            <v-flex xs6 lg6>
              <div class="my-2 ml-2 text-left">
                <v-btn color="#fc9208e6" x-large dark class="button button5" id="btn" @click="fn_gotoregister()">
                  <div>
                  <v-flex xs12 lg12>
                    <v-icon style="font-size: 36px;">clear</v-icon>
                  </v-flex>
                  <v-flex xs12 lg12>
                    <div style="font-size: 20px;">ไม่มีบัญชี</div>
                  </v-flex>
                  </div>
                </v-btn>
              </div>
            </v-flex>
          </v-layout>
      </v-flex>
      <v-flex v-else>
          <v-layout row wrap align-center justify-center class="ma-2" >
            <v-row class="fill-height ma-2" align="center" justify="center">
              <v-img :src="require('@/assets/img/logo_one-platform-for-drak-background.e306d38.png')" width="400" />
             </v-row>
          </v-layout>
          <v-layout row wrap align-center justify-center class="ma-2">
            <v-flex xs12 lg12 class=" pa-2 text-center">
              <span style="color:white; font-size: 20px;" class="pa-1">ต้องมีบัญชี One Platform เพื่อใช้ในการลงทะเบียน</span>
              <br />
              <span style="color: white; font-size: 20px;">คุณมีบัญชี One Platform หรือไม่?</span>
            </v-flex>
          </v-layout>
          <v-layout row wrap align-center justify-center class="ma-2">
            <v-flex xs6 sm6 md6 >
              <div class="my-2 text-right">
                <v-btn color="success" x-large dark class="button button5" id="btn_" @click="fn_login_regist()">
                  <div>
                  <v-flex xs12 sm12 md12 lg12>
                    <v-icon style="font-size: 36px;">done</v-icon>
                  </v-flex>
                  <v-flex xs12 sm12 md12 lg12>
                    <div style="font-size: 20px;">มีบัญชี</div>
                  </v-flex>
                  </div>
                </v-btn>
              </div>
            </v-flex>
            <v-flex xs6 sm6 md6>
              <div class="my-2 ml-2 text-left">
                <v-btn color="#fc9208e6" x-large dark class="button button5" id="btn_" @click="fn_gotoregister()">
                  <div>
                  <v-flex xs12 sm12 md12 lg12>
                    <v-icon style="font-size: 36px;">clear</v-icon>
                  </v-flex>
                  <v-flex xs12 sm12 md12 lg12>
                    <div style="font-size: 20px;">ไม่มีบัญชี</div>
                  </v-flex>
                  </div>
                </v-btn>
              </div>
            </v-flex>
          </v-layout>
      </v-flex>
    </v-container>
    </v-layout>
  </v-app>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { validationMixin } from "vuelidate";
import { required, maxLength, email } from "vuelidate/lib/validators";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
export default {
  components: {},
  data: function() {
    return {};
  },
  watch: {},
  computed: {
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
  },
  methods: {
    fn_gotoregister(){
      // console.log("เข้าาา");
      this.$router.replace("/registerbusiness");
      localStorage.setItem("stepregister", 1);
    },
    fn_login_regist(){
      // console.log("เข้าจ้าาา");
      this.$router.replace("/login-register");

    }
  },
};
</script>
<style scoped>
#btn {
  width: 20rem;
  height: 12rem;
}
#btn_ {
  width: 12rem;
  height: 9rem;
}

</style>
